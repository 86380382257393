.vis-small-barchart {
    fill: #222222;
    font-size: x-small;
    font-weight: normal;
  }
  
  .tiny-barchart-svg {
    border-bottom: 1px solid #ccc;
  }
  
  .d3-tip {
    background-color: black;
    border-radius: 2px;
    color: white;
    font-size: .8em;
    padding: 14px;
  }