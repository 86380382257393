.vis-horizontal-barchart {
  font-size: 1em;
  box-shadow: 0px 0px 10px #ececec;
  border-radius: 4px;
  margin-right: 20px;
  margin-top: 20px;
  padding: 5px;
}

.vis-horizontal-barchart .graph {
  width: 100%;
}

.vis-horizontal-barchart-values {
  font-size: smaller;
  fill: #999;
}

.vis-horizontal-barchart-label {
  font-size: small;
}

.vis-horizontal-barchart-title {
  font-size: large;
}

/* .vis-horizontal-barchart .bar {
  fill: #bbb;
} */
