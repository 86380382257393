.app-user-guide-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
}

.app-user-guide-nav {
  background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 100px;
  min-width: 250px;
}

.active-nav-item {
  font-size: large;
  font-weight: bold;
}

.app-user-guide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-user-guide-version {
  color: #999;
}

.app-user-guide-header {
  font-size: 2rem;
  font-weight: 500;
}

.app-user-guide-section {
  background-color: #fafafa;
  border-radius: 4px;
  padding: 0.8em;
  margin-bottom: 1em;
  width: 100%;
}

.app-user-guide-section-title {
  background-color: #eee;
  border-radius: 6px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 30px;
  /* margin-left: 30px; */
  padding: 15px;
}

.app-user-guide-section-body {
  color: #000;
  font-size: 1.1rem;
  /* margin-bottom: 15px; */
  margin-left: 30px;
  margin-right: 30px;
}

.app-user-guide-section-body-two-col {
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.1rem;
  /* margin-bottom: 30px; */
  margin-left: 30px;
  margin-right: 30px;
}

.app-user-guide-section-text {
  margin-right: 50px;
}

.app-user-guide-bold-text-container {
  background-color: #ffeffe;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}
.app-user-guide-bold-text {
  color: purple;
  font-size: 1.2em;
}

.app-user-guide-section-body-img {
  /* float: right; */
  width: 100%;
}

.app-user-guide-sub-bullet {
  font-size: small;
}

.app-user-guide-toc {
  display: flex;
  justify-content: flex-start;
  /* margin-left: 60px; */
  width: 100%;
}

.app-user-guide-toc-link {
  font-size: normal;
}

.app-guide-image {
  box-shadow: 0px 2px 15px #ddd;
  /* float: right; */
  /* margin-left: 20px; */
  margin-bottom: 5px;
}

.app-user-guide-image-caption {
  font-size: normal;
  font-style: italic;
}

.app-user-guide-icons {
  color: #999;
  font-size: 1.6rem;
  margin-right: 10px;
}

li {
  margin-bottom: 5px;
}

div > h5 {
  font-weight: 600;
  padding-top: 20px;
}
p > h5 {
  font-weight: 600;
}
