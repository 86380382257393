.Tip__compact {
  cursor: pointer;
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.Tip__card {
  padding: 10px;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  /* box-shadow: 0 3px 10px rgba(37, 40, 43, 0.2); */
  box-shadow: 0px 4px 28px rgba(37, 40, 43, 0.4);
  width: 270px;
}

.Tip__card textarea {
  font-size: 16px;
  width: 244px;
  height: 70px;
}

.Tip__card input[type="submit"] {
  margin-top: 5px;
  font-size: large;
}

.Tip_quick_select_context {
  background-color: "#ffffffcc";
  border-radius: "4px";
  cursor: "pointer";
  display: "flex";
  align-items: "center";
  justify-content: "space-around";
  height: "20px";
  margin-right: "3px";
  padding: "3px";
  width: "20px";
}

.Tip_context_entry {
  font-size: .875em;
  font-weight: 600;
}
.Tip_quick_select_context {
  background-color: #ffffffee;
  border-radius: 4px;
}
.Tip_quick_select_context:hover {
  background-color: #ffffffff;
  box-shadow: 0px 0px 5px #777777;
}
