@font-face {
  font-family: "Open_Sans";
  src: local("Open_Sans");
  src: url(./fonts/Open_Sans/OpenSans-VariableFont_wdth_wght.ttf);
}

body {
  margin: 0;
  font-family: "Open_Sans";
  /* font-family: "Arial", "Open Sans", "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Extra small devices (phones, 786px and down) */
@media only screen and (max-width: 786px) {
  body {
    font-size: .8em;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 1em;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */

/* #viewerContainer > #viewer > .page > .textLayer ::selection {
  background-color: yellow;
}
canvas {
  filter: invert(100%);
} */
