.AreaHighlight {
  border: 1px solid #333;
  background-color: rgba(252, 232, 151, 1.0);
  opacity: 1;
  mix-blend-mode: multiply;
}

.AreaHighlight__part {
  border: 3px dotted rgb(150, 150, 150);
  cursor: pointer;
  position: absolute;
  background: rgba(255, 226, 143, .5);
  transition: background 0.3s;
}

.AreaHighlight--scrolledTo .AreaHighlight__part {
  border: 2px solid rgb(147, 58, 146, 1);
  background: #c186d444;
}