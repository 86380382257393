.splash-screen {
  height: 100vh;
}
.image-bg {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 600px !important;
  background-image: url(../../../assets/backgrounds/kg.png);
  background-position: top;
  background-repeat:no-repeat;
  /* height: 100%; */
  /* background-size: contain; */
}
.img-fluid {
  width: 100%;
}

.splash-screen-carousel-item {
  display: flex;
  justify-content: space-around;
}

.splash-screen-carousel-caption {
  color: var(--primary);
  font-weight: bold;
  margin-bottom: 0px;
  text-align: center;
}

.splash-screen-carosel-item-contents {
  font-size: 2em;
  color: #666;
}

.splash-screen-carousel-container {
  margin-bottom: 25px;
}

.splash-screen-snackbar {
  width: 400px;
  opacity: 0.9;
}
