.doc-tabs {
  flex: 1 0 auto !important;
}
.doc-tabs .nav-link {
  height: 40px;
  width: 300px;
}

.nav-item {
  cursor: pointer;
}