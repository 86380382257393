.App {
  text-align: center;
}
.login {
  max-width: 33%;
  padding: 20px 10px;
  margin: 0 auto;
}
.login_span {
  color: #0074b1;
  cursor: pointer;
}
.login_p {
  margin-top: 20px;
}
.login-signup-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.login-signup-error {
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ef4836;
}

.login-form-subtitle {
  color: gray;
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.warning-computer-usage {

  color: red;
  display: flex;
  font-size: small;
  justify-content: space-around;
  padding: 10px;
}
